import React from "react"
import {Link} from 'gatsby';
import Layout from "../../components/bluescope/layout"
import seologo from "../../images/bluescope/appicon256.png"
import Seo from "../../components/SEO"

function BlueScopeTermsPage(props) {

    const seoImage = {
		src: seologo,
		height: 256,
		width: 256,
		alt: "BlueScope logo",
    };

    return (
    <Layout>
        <Seo
            siteTitle={'BlueScope® Terms of Use'}
            seoTitle='BlueScope Terms of Use'
            path={props.location.pathname}
            metaImage={seoImage}
            icon={seologo}
            description='BlueScope® - Terms of Use'
            keywords={["BlueScope", "BlueScope Terms of Use", "BlueScope Terms"]}
            datePublished="12-13-2020"
            dateModified="05-08-2023"
        />
        
        <div className={"container"}  style={{textAlign: 'left'}}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1 style={{textDecoration: 'None', color: '#0398F4'}}>BlueScope® for iOS</h1>
                    <h2>Terms of Use</h2>
                </div>

                <div>
                <h6 style={{textAlign: 'left'}}>
                    Last Updated: May 08, 2023
                </h6>
                </div>

                <p style={{textAlign: 'left'}}>
                <Link to="/bluescope" style={{textDecoration: 'None', color: '#0398F4'}}>BlueScope® for iOS</Link> provides one of the best ways to manage your BLE (Bluetooth® Low Energy) devices. BlueScope® is the property of <Link to="/" style={{textDecoration: 'None', color: '#0398F4'}}>Malaud, LLC</Link>. BlueScope® utilizes Bluetooth® framework to connect to BLE devices around you. You agree to the following Terms of Use ("Terms") by installing or using this app, related services, or website (together, "Services").
                </p>
                
                <h3>Legal and Acceptable Use</h3>
                <p>
                    You agree to use BlueScope® only for legal, authorized and acceptable purposes. You will not use the service in a way that (a) violate or infringe the rights of the Company (Malaud, LLC), other app users, or others, including privacy, intellectual property, or other proprietary rights; (b) involve collecting information about other users and other devices that you do not own yourself; (c) involve illegally try to track other users or devices that you do not own yourself without the explicit permission of the owners of such devices.
                </p>

                <h3>Harm to the Company</h3>
                <p>
                    You must not use the service to access, use, modify, distribute, transfer, or exploit our Services in unauthorized manners, or in ways that harm the owning Company (Malaud, LLC), our services, or our systems. For example, you must not (a) try to gain unauthorized use of our Services and our Systems; (b) disrupt the integrity or performance of our Services and Systems; (c) try to gain unauthorized access to the premium service; (d) sell, rent, or charge for our Services.
                </p>

                <h3>Intellectual Property</h3>
                <p>
                    Malaud LLC owns all copyrights, trademarks, domains, logos, trade dress, trade secrets, patents, and other intellectual property rights associated with our Software and Services. You may not use our copyrights, trademarks, domains, logos, trade dress, patents, and other intellectual property rights unless you have our written permission. To report copyright, trademark, or other intellectual property infringement, please <Link to="/contact" style={{textDecoration: 'None', color: '#0398F4'}}>contact us</Link>.
                </p>

                <h3>Software</h3>
                <p>
                    You consent to downloading and installing updates to the software in order to enable bug fixes, new features, and enhanced functionality.
                </p>

                <h3>Payment Terms</h3>
                <p>
                    Premium features require monthly or annual subscription as applicable. You have the right to cancel the subscription for any reason at any time. If you cancel and re-enable the subscription at a later time, any free introductory offers previously consumed may not be available to you. You can upgrade or downgrade the subscription tier from monthly to yearly, and vice versa, at any time and for any reason. We reserve the right to change the subscription pricing for any of our Software or Services at any time.
                </p>

                <h3>Fees and Taxes</h3>
                <p>
                    You're responsible for data and mobile carrier fees and taxes associated with the devices on which you use our software.
                </p>

                <h3>Disclaimers</h3>
                <p>
                    YOU USE OUR SERVICES AT YOUR OWN RISK AND SUBJECT TO THE FOLLOWING DISCLAIMERS. WE PROVIDE THIS SOFTWARE AND RELATED SERVICES ON AN "AS IS" BASIS WITHOUT ANY EXPRESS OR IMPLICIT WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND FREEDOM FROM COMPUTER VIRUSES OR OTHER HARMFUL CODE. MALAUD LLC, DOES NOT WARRANT THAT ANY INFORMATION PROVIDED BY US IS ACCURATE, COMPLETE, OR USEFUL, THAT THE SOFTWARE AND OUR SERVICES WILL BE OPERATIONAL, ERROR FREE, SECURE, OR SAFE, OR THAT OUR SOFTWARE AND SERVICES WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS OR IMPERFECTIONS. WE ARE NOT RESPONSIBLE FOR ACTIONS OR INFORMATION (INCLUDING CONTENT) OF OUR USERS OR OTHER THIRD PARTIES. YOU RELEASE US, AFFILIATES, DIRECTORS, OFFICERS, MANAGERS, EMPLOYEES, PARTNERS, AND AGENTS (TOGETHER, "MALAUD LLC PARTIES") FROM ANY CLAIM, COMPLAINT, CAUSE OF ACTION, CONTROVERSY, OR DISPUTE (TOGETHER, "CLAIM") AND DAMAGES, KNOWN AND UNKNOWN, RELATING TO, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH ANY SUCH CLAIM YOU HAVE AGAINST ANY THIRD PARTIES.
                </p>

                <h3>Limitation of Liability</h3>
                <p>
                    THE MALAUD PARTIES WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR CONSEQUENTIAL, SPECIAL, PUNITIVE, INDIRECT, OR INCIDENTAL DAMAGES RELATING TO, ARISING OUT OF, OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SOFTWARE AND SERVICES, EVEN IF THE MALAUD PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY RELATING TO, ARISING OUT OF, OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SOFTWARE AND SERVICES WILL NOT EXCEED ONE DOLLARS ($1). THE FOREGOING DISCLAIMER OF CERTAIN DAMAGES AND LIMITATION OF LIABILITY WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THE LAWS OF SOME STATES OR JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN OUR TERMS, IN SUCH CASES, THE LIABILITY OF THE MALAUD PARTIES WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
                </p>

                <h3>Dispute Resolution</h3>
                <p>
                    You agree to resolve any Claim you have with us relating to or arising out of our Terms, us, or our Software and Services, exclusively in the United States District court for the Northern District of California or a state court in Santa Clara County, California. You also agree to submit to the personal jurisdiction of such courts for the purpose of litigating all such disputes. The laws of the State of California govern our Terms, as well as any disputes, whether in court or arbitration, which might arise between Malaud LLC, and you, without regard to conflict of law provisions.
                </p>

                <h3>Ending the Terms</h3>
                <p>
                    You may end these Terms with Malaud LLC, at any time by deleting BlueScope and all other apps provided by us, from your device and discontinue the use of our Apps, Software and Services.
                </p>

                <h3>General</h3>
                <p>
                    We may update the Terms from time to time. When we update our Terms, we will update the "Last Modified" date associated with the updated Terms. Your continued use of our Software and Services confirms your acceptance of our updated Terms and supersedes any prior Terms. You will comply with all applicable export control and trade sanctions laws. Our Terms cover the entire agreement between you and Malaud LLC, regarding our Software and Services. If you do not agree to our Terms, you should stop using our Software and Services.
                </p>

            </div>
        </div>

        <div className={"container"}>
            <div className={"notes"}>
                <ul>
                    <li>BlueScope® is a registered trademark of Malaud LLC.</li>
                    <li>Bluetooth® is a registered trademark of Bluetooth SIG.</li>
                    <li>
                        Apple®, iOS®, iPhone®, iPad®, App Store®, Apple Watch®, Apple Maps®, AirPods®, Siri® are registered trademarks of Apple Inc.
                    </li>
                </ul>      
            </div>
        </div>

    </Layout>
    )
}

export default BlueScopeTermsPage
